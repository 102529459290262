import { FaInstagram } from 'react-icons/fa';

const content = {
  title: 'About',
  subtitle: 'Follow us and our pets our instagram',
  links: [
    {
      to: 'https://www.instagram.com/dogify_store/?igshid=10vk9q7p1wmlx',
      text: 'Dogify Store!',
      Icon: FaInstagram,
      newTab: true,
    },
  ],
};

export default content;
