import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContentfulContent from '../contentfulContent/ContentfulContent';
import styles from './style';

const useStyles = makeStyles(styles);

const DescriptionRender = ({ description }) => {
  const classes = useStyles();

  return (
    <div className={classes.DescriptionWrapper}>
      <ContentfulContent content={description.json} />
    </div>
  );
};

export default DescriptionRender;
