import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { LinkList } from 'components';
import aboutUsData from '../../queries/aboutUs.module';
import RenderDescription from '../../components/descriptionsRender/DescriptionRender';

import content from './content';

const { subtitle, links } = content;

const About = () => {
  const { allContentfulAboutUs } = aboutUsData();
  const description =
    allContentfulAboutUs?.nodes[0]
      ?.childContentfulAboutUsAboutUsDescriptionRichTextNode;

  return (
    <>
      <Fade in timeout={1500}>
        <Typography variant='h5' color='textPrimary'>
          <RenderDescription description={description} />
        </Typography>
      </Fade>
      <Fade in timeout={1500}>
        <Typography variant='h3' color='textPrimary'>
          <div style={{ paddingTop: '25px' }}>{subtitle}</div>
        </Typography>
      </Fade>
      <Fade in timeout={1500}>
        <LinkList links={links} />
      </Fade>
    </>
  );
};

export default About;
