import { useStaticQuery, graphql } from 'gatsby';

const aboutUsData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulAboutUs {
          nodes {
            childContentfulAboutUsAboutUsDescriptionRichTextNode {
              json
            }
          }
        }
      }
    `,
  );
  return data;
};

export default aboutUsData;
