import React from 'react';

import { Layout, SEO } from 'components';
import About from '../containers/about/About';

const AboutPage = () => {
  return (
    <Layout>
      <SEO title='About us' />
      <About />
    </Layout>
  );
};

export default AboutPage;
